ul {
    padding: 24px;
    font-weight: 300;
}

li {
    padding-top: 8px;
}

.setting {
    margin-top: 4px;
    margin-bottom: 4px;
    color: var(--grayLight);
    text-align: center;
}

.character {
    color: var(--purple);
    margin-right: 8px;
    font-weight: 500;
}

.time {  
    font-size: 10px;
    font-weight: 500;
    color: var(--grayLight);
    min-width: fit-content;
    display: block;
    margin-right: 8px;
}

.dialogue p {
    display: flex;
    font-weight: 300;
}