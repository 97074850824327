button {
    padding: var(--chipPadding);
    background-color: var(--white05);
    color: var(--white);
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    font-family: var(--monospace);
    border-radius: var(--chipRadius);
    height: var(--chipHeight);
    border: none;
    outline: none;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

button label {
    cursor: pointer;
}

button:hover {
    background-color: var(--white10);
}

button:active {
    background-color: var(--white20);
}