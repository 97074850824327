@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
    font-family: Whitney;
    src: url(../assets/fonts/WhitneyHTF-Book.woff);
    font-weight: 400;
  }

  @font-face {
    font-family: Whitney;
    src: url(../assets/fonts/WhitneyHTF-Medium.woff);
    font-weight: 500;
  }

  @font-face {
    font-family: Whitney;
    src: url(../assets/fonts/WhitneyHTF-Bold.woff);
    font-weight: 600;
  }

:root {
    /* Colors */
    --black: #000000;
    --grayDarkest: #1C1C1C;
    --grayDarker: #2F3136;
    --grayDark: #36393F;
    --grayLight: #72767D;
    --grayLighter: #BBBBBB;
    --white: #FFFFFF;
    --white05: hsla(0, 0%, 100%, 0.05);
    --white10: hsla(0, 0%, 100%, 0.10);
    --white20: hsla(0, 0%, 100%, 0.20);

    /* Discord Chat Colors */
    --red: #EC6451;
    --orange: #EB9039;
    --yellow: #F3CB31;
    --pink: #ED3E77; 
    --purple: #AC74C1;
    --blue: #45AAE0;
    --green: #3BD086;
    --teal: #22C5AC;
    --discord: rgb(88, 101, 242);

    /* Typography */
    --monospace: 'Roboto Mono', monospace;
    --grotesk: 'Whitney', Helvetica, Arial, sans-serif;

    /* Sizing */
    --chipRadius: 8px;
    --chipPadding: 4px 8px;
    --chipHeight: 32px;
  }

  body {
      font-family: var(--monospace);
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      color: var(--white);
      background-color: var(--grayDarkest);
  }

  .icon {
    width: 16px;
}

.icon-left {
    padding-right: 8px;
}


.icon-right {
    padding-left: 8px;
}

p {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
}

.center {
    display: flex;
    justify-content: center;
}

/* Hide scrollbars for sexiness */
 /* Hide scrollbar for Chrome, Safari and Opera */
 *::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  } 

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }