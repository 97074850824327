header {
    border-bottom: 1px solid var(--grayDarker);
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 24px;
}


/* Desktop */
@media (min-width:1025px) { 
    header {
         height: 10vh;
    } 
 }