.stage__wrapper {
    width: 95vw;;
    height: 88vh;
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
}

.stage {
    background: var(--grayDark);
    height: 100%;
    border-radius: var(--chipRadius);
    font-family: var(--grotesk);
    margin-top: 16px;
    margin-bottom: 16px;
    overflow: scroll;
}


/* Desktop */
@media (min-width:1025px) { 
   .stage__wrapper {
        min-width: 480px;
        max-width: 40vw;
        min-height: 480px;
        max-height: 70vh;
        margin-top: 5vh;
   } 
}