.stage__header {
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #2C2F33;
    font-weight: 600;
    font-family: var(--grotesk);
    position: sticky;
    top: 0;
    background-color: var(--grayDark);
}

.icon--hashtag {
    opacity: 0.4;
    margin-right: 8px;
}

.icon--dropdown {
    opacity: 0.4;
    margin-left: 8px;
}

/* Dropdown Header */

.dropdown__header {
    padding: 8px;
    margin-left: 16px;
    color: var(--white);
    border-radius: var(--chipRadius);
    width:fit-content;
    border: none;
    outline: none;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 100ms ease-in-out;
}

.dropdown__header:hover {
    background-color: var(--white05);
    transition: 100ms ease-in-out;
}

/* Dropdown Results */

.dropdown__results {
    position: absolute;
    top: 64px;
    background-color: var(--grayDarkest);
    margin-left: 24px;
    padding: 8px 12px;
    width: 220px;
    max-height: 240px;
    overflow: scroll;
    border-radius: var(--chipRadius);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 16px 0px;
}

.dropdown__item {
    display: flex;
    align-items: center;
    /* min-height: 32px; */
    padding: 8px 6px;
    border-radius: 2px;
    /* border-bottom: 1px var(--grayDarker) solid; */
}

.dropdown__item:hover {
    background-color: var(--discord);
    cursor: pointer;
}